import { useState } from "react"
import styled from "styled-components"

import { useConnectOauth } from "src/data/integrations/queries/integrationAuthQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"

export function AirbnbUpdateDialog({
  integration,
}: {
  integration: TIntegration
}) {
  const { orgId } = useOrganization()
  const connectOauth = useConnectOauth({ ownerType: OwnerType.ORGANIZATION })

  const [redirecting, setRedirecting] = useState(false)
  // resetting skip on every mount is by design:
  const [airbnbUpdateSkipped, setAirbnbUpdateSkipped] = useState(false)

  const isAirbnbIntegration = integration.integration_identifier === "airbnb"
  const airbnbUpdateAvailable =
    isAirbnbIntegration &&
    integration.connection_status?.some((s) => s.status === "update_available")

  function handleOauthFlow() {
    setRedirecting(true)
    connectOauth.mutate(
      {
        orgId,
        redirectUrl: window.location.href,
        integration: integration.integration_identifier,
        reauthorize: true,
      },
      {
        onError: (e) => {
          setRedirecting(false)
        },
      }
    )
  }

  const showAirbnbDisclosureDialog =
    isAirbnbIntegration && airbnbUpdateAvailable && !airbnbUpdateSkipped

  if (showAirbnbDisclosureDialog) {
    return (
      <AirbnbDisclosureDialog
        open={showAirbnbDisclosureDialog}
        onClose={() => {
          setAirbnbUpdateSkipped(true)
        }}
        onConfirm={handleOauthFlow}
        loading={redirecting}
      />
    )
  }

  return null
}

function AirbnbDisclosureDialog({
  open,
  onClose,
  onConfirm,
  loading,
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  loading?: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <ConfirmDialog
      title={t(langKeys.integrations_airbnb_disclose_title)}
      confirmLabel={t(langKeys.agree)}
      cancelLabel={t(langKeys.skip_for_now)}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
    >
      <DialogBody>{t(langKeys.integrations_airbnb_disclose_body)}</DialogBody>
    </ConfirmDialog>
  )
}

const DialogBody = styled.div`
  white-space: pre-line;
`
