import styled from "styled-components"

import { SectionTitle } from "src/ui/Layout/SectionTitle"
import { spacing } from "src/ui/spacing"

export function About() {
  return (
    <Section>
      <SectionTitle>What can this integration do?</SectionTitle>
      <Text>
        If the Property Management System you're using does not yet have a
        dedicated integration, you can use this functionality to import guests
        from a file, if your system is able to export your guests to either a
        .csv or an .ics file.
      </Text>

      <SectionTitle>How does it work?</SectionTitle>
      <Text>
        When you go to the "Upload file" tab you will be able to paste your
        exported file. Our system will then attempt to parse the file and add
        the guests to the corresponding Minut home.
      </Text>
    </Section>
  )
}

const Section = styled.div`
  max-width: 720px;
`

const Text = styled.p`
  margin: ${spacing.S} 0;
  font-size: 1rem;
`
