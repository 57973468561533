import { useState } from "react"
import styled from "styled-components"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { AboutIntegrationTab } from "src/components/Integrations/Integration/AboutIntegrationTab"
import { IntegrationDetailsHeader } from "src/components/Integrations/Integration/IntegrationDetailsHeader"
import { LinkHomesTab } from "src/components/Integrations/Integration/LinkHomesTab"
import { SettingsTab } from "src/components/Integrations/Integration/SettingsTab"
import { AirbnbUpdateDialog } from "src/components/Integrations/IntegrationConnectDialogs/AirbnbUpdateDialog"
import { IntegrationConnectDialogs } from "src/components/Integrations/IntegrationConnectDialogs/IntegrationConnectDialogs"
import { usePostIntegrationConnectInitiated } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { useIntegrationAvailability } from "src/data/featureAvailability/logic/useIntegrationAvailability"
import { isExternalLinkIntegration } from "src/data/integrations/logic/integrations"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useUrlParam } from "src/hooks/useUrlParam"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { BackButton } from "src/ui/BackButton/BackButton"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function IntegrationView({
  integration,
}: {
  integration: TIntegration
}) {
  const { t } = useTranslate()
  const { navigate } = useRouter()
  const { orgId } = useOrganization()
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)
  const integrationAvailability = useIntegrationAvailability({
    integrationId: integration.integration_identifier,
    integrationType: integration.type,
    refresh: true,
  })
  const [startAuthFlow, setStartAuthFlow] = useState(false)
  const initiateOauth = useUrlParam("initiateOauth")
  const isExternalLinking = isExternalLinkIntegration(integration)
  const homesTabId = "homes"
  const settingsTabId = "settings"
  const aboutTabId = "about"

  const postIntegrationConnectEvent = usePostIntegrationConnectInitiated()

  // This is only being used once, since it should only apply on initial loading of the page.
  useEffectOnce(() => {
    if (initiateOauth.value === "true" && !integration.configured) {
      setStartAuthFlow(true)
    }
  })

  const { name, description, rich_description } = integration

  const tabsDisabled =
    !integration.configured || !integrationAvailability.available

  const tabs: ITab[] = [
    {
      id: homesTabId,
      label: langKeys.home_plural,
      disabled: tabsDisabled,
      view: <LinkHomesTab integration={integration} tabId={homesTabId} />,
      hidden: isExternalLinking,
    },
    {
      id: settingsTabId,
      label: langKeys.settings,
      disabled: !integration.configured, // Allow this to be accessed even if not availble to be able to disconnect integration
      view: <SettingsTab integration={integration} tabId={settingsTabId} />,
      hidden: isExternalLinking,
    },
    {
      id: aboutTabId,
      label: langKeys.integrations_about_integration,
      view: (
        <AboutIntegrationTab
          integration={integration}
          tabId={aboutTabId}
          description={description}
          richDescription={rich_description}
        />
      ),
    },
  ].filter((tab) => !tab.hidden)

  const initialTabIndex = !integration.configured
    ? tabs.indexOf(tabs.find((t) => t.id === aboutTabId) || tabs[0])
    : 0

  function handleConnectIntegration() {
    if (!integrationAvailability.available) {
      return setShowUpgradeDialog(true)
    }

    postIntegrationConnectEvent.mutate({ integration_name: integration.name })

    setStartAuthFlow(true)
  }

  function handleUpgradePlan() {
    navigate(
      Routes.ChangePlan.location({
        plan: integrationAvailability.required_plan_types?.[0],
      })
    )
  }

  return (
    <div>
      <BackButton to={Routes.OrgIntegrations.location()}>
        {t(langKeys.back)}
      </BackButton>

      <MainView
        helmet={name}
        title={
          <IntegrationDetailsHeader
            integration={integration}
            integrationsAvailability={integrationAvailability}
          />
        }
        titleBarProps={{
          actionBar: !integration.configured && (
            <MButtonLegacy onClick={handleConnectIntegration} size="large">
              {t(langKeys.connect)}
            </MButtonLegacy>
          ),
        }}
      >
        <Tabs labels={tabs} value={initialTabIndex}>
          {tabs.map((t) => {
            return <StyledTabPanel key={t.label}>{t.view}</StyledTabPanel>
          })}
        </Tabs>

        {isExternalLinking ? (
          <ConfirmDialog
            title={`Connect to ${integration.name}`}
            open={startAuthFlow}
            onClose={() => setStartAuthFlow(false)}
            onConfirm={() =>
              (window.location.href = integration.setup_link ?? "")
            }
          >
            {integration.setup_instructions}
          </ConfirmDialog>
        ) : (
          <IntegrationConnectDialogs
            startAuthFlow={startAuthFlow}
            setStartAuthFlow={setStartAuthFlow}
            integration={integration}
            orgId={orgId}
          />
        )}
      </MainView>

      <UpgradeBlockerDialog
        open={showUpgradeDialog}
        onConfirm={handleUpgradePlan}
        onClose={() => setShowUpgradeDialog(false)}
      />

      <AirbnbUpdateDialog integration={integration} />
    </div>
  )
}

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`
