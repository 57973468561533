import { useState } from "react"
import styled from "styled-components"

import { Checkbox, FormControlLabel } from "@material-ui/core"

import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { spacing } from "src/ui/spacing"

// eslint-disable-next-line react/prop-types
export function FilePasteContainer({ onSubmit, loading }) {
  const [data, setData] = useState("")
  const [dryRun, setDryRun] = useState(true)

  function onChange(e) {
    setData(e.target.value)
  }

  function dryRunChange(e) {
    const checked = e.target.checked
    setDryRun(checked)
  }

  return (
    <Box>
      <TextBox onChange={onChange}></TextBox>

      <Buttons>
        <FormControlLabel
          control={
            <Checkbox
              checked={dryRun}
              onChange={dryRunChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Dry-run"
        />

        <MButtonLegacy
          onClick={() => onSubmit({ rawData: data, dryRun })}
          disabled={!data}
          loading={loading}
        >
          Submit
        </MButtonLegacy>
      </Buttons>
    </Box>
  )
}

const Buttons = styled.div`
  display: inline-grid;
  justify-content: right;
  align-items: center;
  grid-template-columns: auto auto;
  grid-column-gap: ${spacing.M};
`

const Box = styled.div`
  display: grid;
  grid-row-gap: ${spacing.M};
`

const TextBox = styled.textarea`
  min-width: 400px;
  min-height: 200px;
  width: 100%;
  font: inherit;
`
