/* eslint-disable react/prop-types */
import styled from "styled-components"

import { SectionTitle } from "src/ui/Layout/SectionTitle"
import { spacing } from "src/ui/spacing"

export function CsvImportErrors({ importedState }) {
  return (
    <Box>
      {importedState && importedState.errors.length === 0 && (
        <SectionTitle>
          {importedState.validGuests.length} guest
          {importedState.validGuests.length !== 1 && "s"} imported!
        </SectionTitle>
      )}

      {importedState && importedState.validGuests.length !== 0 && (
        <div>
          {importedState.validGuests.map((guest, index) => {
            return (
              <GuestParagraph key={index}>
                {guest.home.name}: {guest.name}, {String(guest.checkIn)} -{" "}
                {String(guest.checkOut)}
              </GuestParagraph>
            )
          })}
        </div>
      )}

      {importedState && importedState.errors.length !== 0 && (
        <div>
          <SectionTitle>There were issues with your file</SectionTitle>
          <ul style={{ margin: 0 }}>
            {importedState.errors.map((error, index) => {
              return <li key={index}>{error}</li>
            })}
          </ul>
        </div>
      )}

      {importedState && importedState.invalidGuests.length !== 0 && (
        <div>
          <SectionTitle>There were issues with these bookings:</SectionTitle>
          {importedState.invalidGuests.map((guest, index) => {
            return (
              <div key={index}>
                <GuestParagraph>{guest.name}: </GuestParagraph>
                {guest.errors.map((error, index2) => {
                  return <SubText key={index2}>{error}</SubText>
                })}
              </div>
            )
          })}
        </div>
      )}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  grid-row-gap: ${spacing.M};
`

const GuestParagraph = styled.p`
  margin: 0;
  font-size: 1rem;
`

const SubText = styled.p`
  margin: 0.4rem;
  font-size: 0.7rem;
`
