/* eslint-disable react/prop-types */
import "date-fns"

import { useState } from "react"
import styled from "styled-components"

import DateFnsUtils from "@date-io/date-fns"
import { Dialog } from "@material-ui/core"
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"

import { spacing } from "src/ui/spacing"

export function TimePickPopup({
  open,
  checkInSet,
  checkOutSet,
  buttons,
  onClose,
}) {
  const [selectedCheckIn, setSelectedCheckIn] = useState(
    new Date("2014-08-18T12:00:54")
  )
  const [selectedCheckOut, setSelectedCheckOut] = useState(
    new Date("2014-08-18T12:00:54")
  )

  const handleCheckIn = (date) => {
    setSelectedCheckIn(date)
    checkInSet(date)
  }

  const handleCheckOut = (date) => {
    setSelectedCheckOut(date)
    checkOutSet(date)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <Title>Choose check-in and check-out times</Title>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <KeyboardTimePicker
              margin="normal"
              id="check-in"
              label="Check in"
              ampm={false}
              value={selectedCheckIn}
              onChange={handleCheckIn}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </div>
          <div>
            <KeyboardTimePicker
              margin="normal"
              id="check-out"
              label="Check out"
              ampm={false}
              value={selectedCheckOut}
              onChange={handleCheckOut}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        <br />
        {buttons}
      </Box>
    </Dialog>
  )
}

const Box = styled.div`
  max-width: "600px";
  padding: ${spacing.M};
  text-align: center;
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: ${spacing.M};
  margin-bottom: ${spacing.XS};
`
