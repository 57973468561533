import { Dispatch, SetStateAction } from "react"
import styled from "styled-components"

import { Chip } from "src/components/Chip"
import { FilterAdd } from "src/components/Filter/FilterAdd"
import SearchFilter from "src/components/Filter/SearchFilter"
import {
  useTrackIntegrationFilterAppliedEvent,
  useTrackIntegrationFiltersViewedEvent,
} from "src/data/integrations/hooks/useSendIntegrationEvents"
import { integrationFiltersList } from "src/data/integrations/logic/integrations"
import {
  TIntegration,
  TIntegrationFilter,
} from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

export function IntegrationsFilterSearch({
  activeFilters,
  onSelectFilter,
  onSearch,
  configuredIntegrations,
  isFetched,
}: {
  activeFilters: TIntegrationFilter[]
  onSelectFilter: Dispatch<SetStateAction<TIntegrationFilter[]>>
  onSearch: (value: string) => void
  configuredIntegrations: TIntegration[]
  isFetched: boolean
}) {
  const { t, langKeys } = useTranslate()

  const { postIntegrationFiltersViewEvent } =
    useTrackIntegrationFiltersViewedEvent({
      configuredIntegrations,
      isFetched,
    })

  const { postIntegrationFiltersAppliedEvent } =
    useTrackIntegrationFilterAppliedEvent({
      configuredIntegrations,
      isFetched,
    })

  function handleAddFilter(filter: TIntegrationFilter) {
    onSelectFilter((currFilters) => {
      const updatedFilters = [...currFilters, filter]

      postIntegrationFiltersAppliedEvent(updatedFilters)

      return updatedFilters
    })
  }

  function handleRemoveFilter(filter: TIntegrationFilter) {
    onSelectFilter((currFilters) => {
      const updatedFilters = currFilters.filter((f) => f.id !== filter.id)

      postIntegrationFiltersAppliedEvent(updatedFilters)

      return updatedFilters
    })
  }

  function handleClick() {
    postIntegrationFiltersViewEvent()
  }

  return (
    <div>
      <FilterBox>
        <SearchFilter
          label={t(langKeys.search_for_integration)}
          onChange={(value) => onSearch(value)}
          initialValue={""}
          delay={500}
          minWidth={30}
        />

        {integrationFiltersList.length > 0 && (
          <FilterAdd
            filters={integrationFiltersList}
            activeFilters={activeFilters}
            onAddFilter={handleAddFilter}
            onClearFilters={() => {
              onSelectFilter([])
            }}
            onClick={handleClick}
          />
        )}
      </FilterBox>

      {activeFilters.length >= 1 && (
        <FilterChipBox>
          {activeFilters.map((f, index) => {
            const label = (
              <span>
                {f.filterText || f.name(t)}
                {f.showValue && `: `}
                {f.showValue && <b>{f.value}</b>}
              </span>
            )
            return (
              <Chip
                label={label}
                onDelete={() => handleRemoveFilter(f)}
                variant="outlined"
                color="primary"
                key={`${f.id}-${index}`}
              />
            )
          })}
        </FilterChipBox>
      )}
    </div>
  )
}

const FilterBox = styled.div`
  display: flex;
  gap: ${spacing.M};
`

const FilterChipBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing.S};
  margin-top: ${spacing.M};
`
