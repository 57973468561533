import { MenuItem, MenuList } from "@material-ui/core"

import { TAvailableNestThermostatMode } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { MText } from "src/ui/MText"

export function SelectNestThermostatMode({
  commonAvailableNestModes,
  homeId,
  defaultNestMode,
  linked,
}: {
  commonAvailableNestModes: TAvailableNestThermostatMode[]
  homeId: string
  defaultNestMode: TAvailableNestThermostatMode | undefined
  linked: boolean
}) {
  const { t } = useTranslate()
  const { orgId } = useOrganization()

  const patchHome = usePatchHome()

  const displayValue = defaultNestMode
    ? translateNestMode(defaultNestMode, t)
    : t(langKeys.none)

  if (!commonAvailableNestModes || !linked) {
    return <div></div>
  }

  function handleOnClick(mode: TAvailableNestThermostatMode) {
    patchHome.mutate({
      orgId,
      homeId,
      data: { thermostat_mode_on_checkin: mode },
    })
  }

  return (
    <div>
      <DropdownButton
        displayValue={<MText>{displayValue}</MText>}
        variant="minimal"
      >
        {({ closeMenu }) => (
          <MenuList>
            {commonAvailableNestModes.map((mode) => (
              <MenuItem
                key={mode}
                onClick={() => {
                  handleOnClick(mode)
                  closeMenu()
                }}
              >
                {translateNestMode(mode, t)}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </DropdownButton>
    </div>
  )
}

function translateNestMode(
  mode: TAvailableNestThermostatMode,
  t: TTranslateFunction
) {
  if (mode === "heat") {
    return t(langKeys.integrations_thermostat_mode_heat)
  }

  if (mode === "cool") {
    return t(langKeys.integrations_thermostat_mode_cool)
  }

  return t(langKeys.integrations_thermostat_mode_heatcool)
}
