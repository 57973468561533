import GuestImportIcon from "src/components/Integrations/StaticIntegrations/GuestImport/guest-import.svg"
import { TIntegrationStatic } from "src/data/integrations/types/integrationTypes"
import FileImportIcon from "src/ui/icons/database.svg"

export const staticIntegrations: TIntegrationStatic[] = [
  {
    name: "Import Guests",
    integration_identifier: "import-guests",
    summary: "Perform bulk imports of guests from exported .csv files",
    description: "Import guests from a file",
    type: "automation",
    static: true,
    plan_requirements: ["pro", "standard"],
    iconComponent: <FileImportIcon />,
    rich_description: "Perform bulk imports of guests from exported .csv files",
  },
  {
    name: "Guest Importer",
    integration_identifier: "guest-importer",
    summary: "Perform bulk imports of guests from exported .csv files",
    description: "Import guests from a file",
    type: "automation",
    static: true,
    plan_requirements: ["pro", "standard"],
    iconComponent: <GuestImportIcon />,
    rich_description: "Perform bulk imports of guests from exported .csv files",
  },
]
