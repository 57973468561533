import { useState } from "react"
import styled from "styled-components"

import { MultiAccountConnectView } from "src/components/Integrations/Integration/MultiAccountConnectView"
import { ConfirmDisconnectDialog } from "src/components/Integrations/IntegrationConnectDialogs/ConfirmDisconnectDialog"
import { IntegrationConnectDialogs } from "src/components/Integrations/IntegrationConnectDialogs/IntegrationConnectDialogs"
import { useTrackIntegrationViewedEvent } from "src/data/integrations/hooks/useSendIntegrationEvents"
import { useIntegrationDelete } from "src/data/integrations/queries/integrationQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { spacing } from "src/ui/spacing"

export function SettingsTab({
  integration,
  tabId,
}: {
  integration: TIntegration
  tabId: string
}) {
  const { orgId } = useOrganization()
  const { navigate } = useRouter()

  const { t, langKeys } = useTranslate()

  const [startAuthFlow, setStartAuthFlow] = useState(false)
  const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false)

  const disconnectMutation = useIntegrationDelete()
  const disconnect = () =>
    disconnectMutation.mutateAsync(
      {
        integration: integration.integration_identifier,
        orgId,
        ownerType: OwnerType.ORGANIZATION,
      },
      {
        onSuccess: () => {
          navigate(
            Routes.OrgIntegration.location(integration.integration_identifier),
            { replace: true }
          )
        },
      }
    )
  const loading = disconnectMutation.isLoading

  useTrackIntegrationViewedEvent({
    integration,
    tabId,
  })

  return (
    <div>
      {integration.auth.multi_account_support && (
        <MultiAccountBox>
          <MultiAccountConnectView
            integration={integration}
            setStartAuthFlow={setStartAuthFlow}
          />
        </MultiAccountBox>
      )}

      <MButtonLegacy
        size="large"
        variant="outlined"
        emergency
        loading={loading}
        onClick={() => setShowConfirmDisconnect(true)}
      >
        {t(langKeys.disconnect)}
      </MButtonLegacy>

      <IntegrationConnectDialogs
        integration={integration}
        orgId={orgId}
        setStartAuthFlow={setStartAuthFlow}
        startAuthFlow={startAuthFlow}
      />

      <ConfirmDisconnectDialog
        name={integration.name}
        integrationId={integration.integration_identifier}
        open={showConfirmDisconnect}
        onClose={() => setShowConfirmDisconnect(false)}
        loading={loading}
        onConfirm={async () => {
          await disconnect()
          setShowConfirmDisconnect(false)
        }}
      />
    </div>
  )
}

const MultiAccountBox = styled.div`
  margin-bottom: ${spacing.XL};
`
