import { Route, Switch } from "react-router-dom"

import { IntegrationDetailsSkeleton } from "src/components/Integrations/Integration/IntegrationDetailsHeader"
import { IntegrationView } from "src/components/Integrations/Integration/IntegrationView"
import { IntegrationsList } from "src/components/Integrations/IntegrationsList"
import { GuestImport } from "src/components/Integrations/StaticIntegrations/GuestImport/GuestImport"
import { PrivateRoute } from "src/components/PrivateRoute/PrivateRoute"
import { RouteNotFound } from "src/components/RouteNotFound/RouteNotFound"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { Routes } from "src/router/routes"

import { ImportGuests } from "./StaticIntegrations/FileImport"

export function Integrations() {
  const { org } = useOrganization()

  const fetchIntegrations = useFetchIntegrations({
    orgId: org.id,
    filters: { includeExternalIntegrations: true },
  })
  const integrations = fetchIntegrations.data?.integrations || []

  return (
    <Switch>
      <PrivateRoute
        exact
        path={Routes.OrgIntegration.location("import-guests").pathname}
        component={ImportGuests}
      />

      <PrivateRoute
        exact
        path={Routes.OrgIntegration.location("guest-importer").pathname}
        component={GuestImport}
      />

      <PrivateRoute exact path={Routes.OrgIntegrations.location().pathname}>
        <IntegrationsList />
      </PrivateRoute>

      {integrations.map((i) => {
        return (
          <PrivateRoute
            key={i.integration_identifier}
            exact
            path={
              Routes.OrgIntegration.location(i.integration_identifier).pathname
            }
          >
            <IntegrationView integration={i} />
          </PrivateRoute>
        )
      })}

      <Route>
        {fetchIntegrations.isLoading ? (
          <IntegrationDetailsSkeleton />
        ) : (
          <RouteNotFound />
        )}
      </Route>
    </Switch>
  )
}
