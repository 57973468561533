import { Fragment } from "react"
import styled from "styled-components"

import { ListItemIcon, ListItemText, Tooltip } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import { LinkStatus } from "src/components/Integrations/Integration/LinkStatus"
import { SelectNestThermostatMode } from "src/components/Integrations/Integration/SelectNestThermostatMode"
import { IntegrationIcon } from "src/components/Integrations/IntegrationIcon"
import { DEFAULT_HOME_NAME, IHome } from "src/data/homes/types/homeTypes"
import {
  checkBrokenIntegration,
  getCommonAvailableNestModes,
} from "src/data/integrations/logic/integrations"
import {
  TIntegration,
  TIntegrationEntities,
  TIntegrationHomeLink,
} from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsV2 } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import InfoIcon from "src/ui/icons/info-i.svg"
import MinutLogo from "src/ui/icons/minut-icon.svg"
import UnlinkIcon from "src/ui/icons/unlink.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LinkHomeTable({
  homes,
  integrationEntities,
  integration,
  homeLinks,
  loading,
  onLinkHome,
  onRemoveLink,
}: {
  homes: IHome[]
  integrationEntities: TIntegrationEntities
  integration: TIntegration
  homeLinks: TIntegrationHomeLink[]
  loading: boolean
  onLinkHome: ({
    homeName,
    homeId,
  }: {
    homeName: string
    homeId: string
  }) => void
  onRemoveLink: ({
    linkId,
    entityName,
    homeName,
  }: {
    linkId: string
    entityName: string
    homeName: string
  }) => void
}) {
  const { t, langKeys } = useTranslate()

  const isGoogleNestIntegration = integration.integration_identifier === "nest"

  const tableHeader = [
    {
      id: "minut-home",
      name: (
        <HeaderBox>
          <IntegrationIcon iconComponent={<MinutLogo />} size={24} />
          {t(langKeys.minut_home)}
        </HeaderBox>
      ),
      hidden: false,
    },
    {
      id: "entity-home",
      name: (
        <HeaderBox>
          <IntegrationIcon icon={integration.icon} size={24} />
          {t(langKeys.integrations_integration_home, {
            name: integration.name,
          })}
        </HeaderBox>
      ),
      hidden: false,
    },
    {
      id: "devices",
      name: t(langKeys.sensor_plural),
      hidden: !isGoogleNestIntegration,
    },
    { id: "thermostat-mode", name: t(langKeys.status), hidden: false },
    {
      name: (
        <ThermostatModeHeader>
          {t(langKeys.integrations_thermostat_mode_title)}
          <Tooltip title={t(langKeys.integrations_thermostat_mode_details)}>
            <InfoIcon fill="currentColor" width={18} />
          </Tooltip>
        </ThermostatModeHeader>
      ),
      hidden: !isGoogleNestIntegration,
    },
    { id: "more-button", name: "", hidden: false },
  ]

  const visibleHeaders = tableHeader.filter((header) => !header.hidden)

  const templateColumns = isGoogleNestIntegration
    ? "auto repeat(4, 1fr) auto"
    : "auto 1fr 1fr auto"

  const isBrokenIntegration = checkBrokenIntegration(integration)

  const rows = homeLinks.map((link) => {
    const home = homes.find((home) => home.home_id === link.home_id)

    const entity = integrationEntities.find(
      (entity) => entity.entity_id === link.entity_id
    )

    const devices = entity?.devices || []

    const commonAvailableNestModes = getCommonAvailableNestModes(devices)

    const linked = !!entity && link.link_status !== "unlinked"
    const isBrokenLink = link.link_status === "broken"

    return (
      <>
        <MText variant="subtitle" key={link.link_id}>
          <InternalLink to={Routes.Home.location(link.home_id)}>
            {link.home_name}
          </InternalLink>
        </MText>

        <EntityColumn
          linked={linked}
          entityName={entity?.name}
          link={link}
          onLinkHome={onLinkHome}
          isBrokenLink={isBrokenLink}
          isBrokenIntegration={isBrokenIntegration}
        />

        {isGoogleNestIntegration && (
          <>
            <div>
              {t(langKeys.integration_thermostat_device_count, {
                count: devices?.length,
              })}
            </div>
          </>
        )}

        <LinkStatus
          devices={devices}
          isGoogleNestIntegration={isGoogleNestIntegration}
          linkStatus={link?.link_status}
          isBrokenIntegration={isBrokenIntegration}
        />

        {isGoogleNestIntegration && (
          <SelectNestThermostatMode
            commonAvailableNestModes={commonAvailableNestModes}
            homeId={link.home_id}
            defaultNestMode={home?.thermostat_mode_on_checkin}
            linked={linked}
          />
        )}

        <div>
          <MoreButton
            menuItems={[
              {
                key: "unlink-home",
                contents: (
                  <>
                    <ListItemIcon>
                      <StyledUnlinkIcon height="24" width="24" />
                    </ListItemIcon>
                    <LisItemUnlinkText
                      primary={t(langKeys.integrations_link_remove)}
                    />
                  </>
                ),
                onClick() {
                  if (!link?.link_id || !entity) return

                  onRemoveLink({
                    linkId: link.link_id,
                    entityName: entity.name,
                    homeName: link.home_name || DEFAULT_HOME_NAME(link.home_id),
                  })
                },
                hidden: !linked,
              },
            ]}
            disabled={!linked}
          />
        </div>
      </>
    )
  })

  return (
    <div>
      <GridTable
        header={visibleHeaders.map((h) => (
          <div key={h.id}>{h.name}</div>
        ))}
        rows={loading ? skeletonRows(isGoogleNestIntegration ? 6 : 4) : rows}
        templateColumns={templateColumns}
        useFallbackResponsiveMode
      />
    </div>
  )
}

function skeletonRows(numOfColumns: 4 | 6) {
  const arrayFromColumns = Array.from({ length: numOfColumns - 1 })

  const createSkeletonRow = (index: number) => (
    <Fragment key={"row-" + index + numOfColumns}>
      {arrayFromColumns.map((_, index2) => (
        <div key={index2 + numOfColumns}>
          <Skeleton
            variant="text"
            width={`${Math.floor(Math.random() * 40) + 60}%`}
          />
        </div>
      ))}

      <div>
        <MoreButton disabled={true} />
      </div>
    </Fragment>
  )

  return Array.from(Array(4), (_, index) => createSkeletonRow(index))
}

function EntityColumn({
  linked,
  entityName,
  link,
  isBrokenLink,
  isBrokenIntegration,
  onLinkHome,
}: {
  linked: boolean
  entityName?: string
  link: TIntegrationHomeLink
  isBrokenLink: boolean
  isBrokenIntegration: boolean
  onLinkHome: ({
    homeId,
    homeName,
  }: {
    homeId: string
    homeName: string
  }) => void
}) {
  const { t, langKeys } = useTranslate()

  if (linked && !!entityName) {
    return <MText variant="subtitle">{entityName}</MText>
  }

  if (isBrokenLink) {
    return (
      <div>
        <StyledTextButton
          onClick={() =>
            onLinkHome({
              homeId: link.home_id,
              homeName: link.home_name || DEFAULT_HOME_NAME(link.home_id),
            })
          }
          disabled={isBrokenIntegration}
        >
          {t(langKeys.integrations_home_link_relink_action)}
        </StyledTextButton>
      </div>
    )
  }

  return (
    <div>
      <LinkButton
        variant="subtle"
        size="small"
        onClick={() =>
          onLinkHome({
            homeId: link.home_id,
            homeName: link.home_name || DEFAULT_HOME_NAME(link.home_id),
          })
        }
        disabled={isBrokenIntegration}
      >
        {t(langKeys.integrations_link_home)}
      </LinkButton>
    </div>
  )
}

const LinkButton = styled(MButton)`
  margin: auto auto auto 0;
`

const StyledUnlinkIcon = styled(UnlinkIcon)`
  color: ${colorsV2.textPrimary};
`

const LisItemUnlinkText = styled(ListItemText)`
  color: ${colorsV2.textPrimary};
`

const ThermostatModeHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS2};
`

const StyledTextButton = styled(TextButton)`
  width: max-content;
`
const HeaderBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
