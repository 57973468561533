import { useState } from "react"
import styled from "styled-components"

import { IntegrationConnectDialogs } from "src/components/Integrations/IntegrationConnectDialogs/IntegrationConnectDialogs"
import { isExternalLinkIntegration } from "src/data/integrations/logic/integrations"
import {
  TIntegration,
  TIntegrationEntities,
  TIntegrationHomeLink,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import {
  systemEmergencyForeground,
  systemWarningForeground,
} from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import InfoIcon from "src/ui/icons/important-filled.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IntegrationAlert({
  homeLinks,
  linksCount,
  homesCount,
  integration,
  integrationEntities,
  isBrokenIntegration,
  hasBrokenLinks,
  error,
}: {
  homeLinks: TIntegrationHomeLink[]
  linksCount: number
  homesCount: number
  integration: TIntegration
  integrationEntities: TIntegrationEntities
  isBrokenIntegration: boolean
  hasBrokenLinks: boolean
  error: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const [showReconnectDialog, setShowReconnectDialog] = useState(false)
  const [startAuthFlow, setStartAuthFlow] = useState(false)

  const isExternal = isExternalLinkIntegration(integration)

  const hasLinkedEntity = integrationEntities.some((entity) =>
    homeLinks.find((link) => link.entity_id === entity.entity_id)
  )

  // #region Google nest specific
  const isGoogleNestIntegration = integration.integration_identifier === "nest"

  const hasHomesWithoutDevices =
    isGoogleNestIntegration &&
    hasLinkedEntity &&
    integrationEntities.some((e) => (e.devices?.length || 0) < 1)

  const hasInactiveDevices =
    isGoogleNestIntegration &&
    hasLinkedEntity &&
    integrationEntities.some((e) =>
      e.devices?.find((d) => d.status === "offline")
    )
  // #endregion Google nest specific

  function handleReconnectClick() {
    setShowReconnectDialog(false)
    setStartAuthFlow(true)
  }

  if (!integration.configured) {
    return null
  }

  if (isBrokenIntegration) {
    return (
      <>
        <MText variant="body">
          <StyledMAlert type="error">
            {`${t(langKeys.integrations_connection_broken_alert_text_no_action)}`}{" "}
            <TextButton onClick={() => setShowReconnectDialog(true)}>
              {t(langKeys.try_reconnecting)}
            </TextButton>
          </StyledMAlert>
        </MText>

        <ConfirmDialog
          title={t(langKeys.integrations_reconnect_integration_title)}
          open={showReconnectDialog}
          onClose={() => setShowReconnectDialog(false)}
          onConfirm={handleReconnectClick}
          confirmLabel={t(langKeys.continue)}
        >
          {t(langKeys.integrations_reconnect_integration_description)}
        </ConfirmDialog>

        <IntegrationConnectDialogs
          startAuthFlow={startAuthFlow}
          setStartAuthFlow={setStartAuthFlow}
          integration={integration}
          orgId={orgId}
          reauthorizationRequired={isBrokenIntegration}
        />
      </>
    )
  }

  if (hasBrokenLinks) {
    return (
      <StyledMAlert type="error">
        <AlertInfo>
          <StyledInfoIcon fill={systemEmergencyForeground} />
          {t(langKeys.integrations_home_links_broken_alert_text)}
        </AlertInfo>
      </StyledMAlert>
    )
  }

  if (isExternal) {
    return (
      <StyledMAlert type="info">
        <MText variant="body">
          {t(langKeys.integrations_external_connection_status_text, {
            name: integration.name,
          })}{" "}
          <ExternalLink href="">
            {t(langKeys.go_to_generic_text, { name: integration.name })}
          </ExternalLink>
        </MText>
      </StyledMAlert>
    )
  }

  if (hasInactiveDevices) {
    return (
      <StyledMAlert type="error">
        {t(langKeys.integrations_nest_some_devices_inactive)}
      </StyledMAlert>
    )
  }

  if (hasHomesWithoutDevices) {
    return (
      <StyledMAlert type="warning">
        {t(langKeys.integrations_nest_no_thermostats_linked)}
      </StyledMAlert>
    )
  }

  if (homesCount === 0) {
    return (
      <MText variant="body">
        <StyledMAlert type="warning">
          <StyledInfoIcon fill={systemWarningForeground} />
          {t(langKeys.integrations_link_homes_to_sync_data_alert_text, {
            name: integration.name,
          })}{" "}
          <InternalLink to={Routes.CreateHome.location().pathname}>
            {t(langKeys.integrations_create_homes_to_get_started_link)}
          </InternalLink>
        </StyledMAlert>
      </MText>
    )
  }

  if (linksCount === 0) {
    return (
      <MText variant="body">
        <StyledMAlert type="warning">
          <StyledInfoIcon fill={systemWarningForeground} />
          {t(langKeys.integrations_link_homes_to_import_data)}
        </StyledMAlert>
      </MText>
    )
  }

  if (error) {
    return (
      <StyledMAlert type="error">
        <AlertInfo>
          <StyledInfoIcon fill={systemEmergencyForeground} />
          {t(langKeys.failed_general_error_try_refreshing_page)}
        </AlertInfo>
      </StyledMAlert>
    )
  }

  return null
}

const StyledMAlert = styled(MBanner)`
  font-weight: unset;
  padding: ${spacing.M} ${spacing.L};
  width: 100%;
`
const StyledInfoIcon = styled(InfoIcon)`
  width: 18px;
  height: 18px;
  margin-right: ${spacing.XS};
`

const AlertInfo = styled.div`
  display: flex;
  align-items: center;
`
