import styled from "styled-components"

import { BackButton } from "src/ui/BackButton/BackButton"
import FileImportIcon from "src/ui/icons/database.svg"
import { DefaultBox } from "src/ui/Layout/DefaultBox"
import { MainContentBox } from "src/ui/Layout/MainContentBox"
import { PageSubtitle } from "src/ui/Layout/PageSubtitle"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { spacing } from "src/ui/spacing"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

import { About } from "./About"
import { UploadFile } from "./UploadFile"

/** Alpha quality release of guest importing from a CSV file. */
export function ImportGuests() {
  return (
    <MainContentBox banner="">
      <BackButton to="/integrations">Back to integrations</BackButton>

      <Top>
        <IconBox>
          <FileImportIcon />
        </IconBox>

        <div>
          <PageTitle>Import Guests</PageTitle>

          <PageSubtitle>Import guests from a file</PageSubtitle>
        </div>
      </Top>

      <Tabs
        labels={[
          { label: "About", id: "about" },
          { label: "Upload file", id: "upload" },
        ]}
      >
        <StyledTabPanel>
          <DefaultBox>
            <About />
          </DefaultBox>
        </StyledTabPanel>

        <StyledTabPanel>
          <DefaultBox>
            <UploadFile />
          </DefaultBox>
        </StyledTabPanel>
      </Tabs>
    </MainContentBox>
  )
}

const Top = styled.div`
  display: flex;
  margin-bottom: ${spacing.XL};
`

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`

const IconBox = styled.div`
  width: 90px;
  margin-right: ${spacing.XL};
`
