import styled from "styled-components"

import { Skeleton } from "@material-ui/lab"

import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"

export function ConfiguredIntegrationProgress({
  homesCount,
  homesLinked,
  isBrokenIntegration,
  brokenLinksCount,
  showProgressBar,
  loading,
}: {
  homesCount: number
  homesLinked: number
  isBrokenIntegration: boolean
  brokenLinksCount: number
  showProgressBar: boolean
  loading: boolean
}) {
  if (loading) {
    return <StyledSkeleton height={20} />
  }
  if (!showProgressBar) {
    return null
  }

  return (
    <LinearProgressBar
      segments={[
        {
          value: ((homesLinked - brokenLinksCount) / homesCount) * 100,
          type: isBrokenIntegration ? "error" : "good",
        },
        {
          value: (brokenLinksCount / homesCount) * 100,
          type: "error",
        },
      ]}
      height="M"
    />
  )
}

const StyledSkeleton = styled(Skeleton)`
  border-radius: 12px;
`
