/* eslint-disable no-console */
import { parseISO } from "date-fns"
import * as Papa from "papaparse"

import { DEFAULT_HOME_NAME } from "src/data/homes/types/homeTypes"

enum FileType {
  CSV = "csv",
  ICS = "ics",
  UNKNOWN = "unknown",
}

type Home = {
  name?: string
  home_id: string
}

interface Guest {
  name: string
  home?: Home
  phone: string
  checkIn: Date
  checkOut: Date
  errors?: string[]
}

export interface IParsedResult {
  validGuests: Guest[]
  invalidGuests: Guest[]
  errors: string[]
}

export class FileParser {
  file: string
  homes: undefined | Home[]
  constructor(file: string, homes: Home[] = []) {
    this.file = file
    this.homes = homes
  }

  testCsv(): boolean {
    const lines = this.file.split("\n")
    if (lines.length <= 0) {
      return false
    }
    const headerColumns = splitComponentsByComma(lines[0])
    console.log(`Header columns: ${headerColumns.length}`)
    for (let i = 1; i < lines.length; ++i) {
      if (lines[i] === "") {
        continue
      }
      const columns = splitComponentsByComma(lines[i])
      if (columns.length !== headerColumns.length) {
        console.log(`line ${i} did not have as many columns: '${columns}'`)
        return false
      }
    }
    return true
  }

  detectType(): FileType {
    if (this.testCsv()) {
      return FileType.CSV
    }
    return FileType.UNKNOWN
  }

  parseCsv(): IParsedResult | undefined {
    const lines = this.file.split("\n")
    const rows = lines.slice(1)
    const columns = splitComponentsByComma(lines[0])

    type Column = {
      name: string
      index: number
    }
    const possiblePhones: Column[] = []
    let possibleGuestNames: Column[] = []
    let possibleHomeNames: Column[] = []
    const possibleCheckIn: Column[] = []
    const possibleCheckOut: Column[] = []

    // 1. Get the possible column names
    columns.forEach((column) => {
      if (column.toLowerCase().includes("phone")) {
        possiblePhones.push({ name: column, index: columns.indexOf(column) })
      }
    })

    columns.forEach((column) => {
      if (
        column.toLowerCase().includes("guest") ||
        column.toLowerCase().includes("name")
      ) {
        possibleGuestNames.push({
          name: column,
          index: columns.indexOf(column),
        })
      }
    })

    columns.forEach((column) => {
      if (
        column.toLowerCase().includes("unit") ||
        column.toLowerCase().includes("property") ||
        column.toLowerCase().includes("home") ||
        column.toLowerCase().includes("name")
      ) {
        possibleHomeNames.push({ name: column, index: columns.indexOf(column) })
      }
    })

    columns.forEach((column) => {
      if (
        column.toLowerCase().includes("checkin") ||
        column.toLowerCase().includes("check-in") ||
        column.toLowerCase().includes("check in") ||
        column.toLowerCase().includes("start")
      ) {
        possibleCheckIn.push({ name: column, index: columns.indexOf(column) })
      }
    })

    columns.forEach((column) => {
      if (
        column.toLowerCase().includes("checkout") ||
        column.toLowerCase().includes("check-out") ||
        column.toLowerCase().includes("check out") ||
        column.toLowerCase().includes("end")
      ) {
        possibleCheckOut.push({ name: column, index: columns.indexOf(column) })
      }
    })

    const errors = []

    if (possibleHomeNames.length === 0) {
      errors.push("Could not find any column with home name")
    }
    if (possiblePhones.length === 0) {
      errors.push("Could not find any column with the guest phone number")
    }
    if (possibleCheckIn.length === 0) {
      errors.push("Could not find any column with the check-in time")
    }
    if (possibleCheckOut.length === 0) {
      errors.push("Could not find any column with the check-out time")
    }
    if (possibleGuestNames.length === 0) {
      errors.push("Could not find any column with the guest name")
    }
    if (errors.length !== 0) {
      return {
        validGuests: [],
        invalidGuests: [],
        errors,
      }
    }

    // 2. If there are more than 1 column for home names try to use values
    // to detemine the correct one
    if (possibleHomeNames.length > 1) {
      let bestMatch = undefined
      let bestMatchNo = 0
      for (let i = 0; i < possibleHomeNames.length; i++) {
        let matches = 0
        const possibleHomeName = possibleHomeNames[i]
        const subset = lines.length > 10 ? lines.slice(1, 10) : lines
        const values = subset.map((line) => {
          return splitComponentsByComma(line)[possibleHomeName?.index]
        })
        values.forEach((value) => {
          if (value !== "" && value !== undefined) {
            this.homes?.forEach((home) => {
              if (home.name !== "") {
                if (
                  value.includes(
                    home.name || DEFAULT_HOME_NAME(home.home_id)
                  ) ||
                  home.name?.includes(value)
                ) {
                  matches++
                }
              }
            })
          }
        })
        if (matches > bestMatchNo) {
          bestMatchNo = matches
          bestMatch = possibleHomeNames[i]
        }
      }
      if (bestMatch) {
        possibleHomeNames = [bestMatch]
      }
    }

    if (possibleHomeNames.length === 1 && possibleHomeNames[0] === undefined) {
      return {
        validGuests: [],
        invalidGuests: [],
        errors: [
          `Could not match the property names in the file to your Minut
          homes. Make sure some part of the property name is present in the
          Minut home name`,
        ],
      }
    }

    // 3. Check if there are multiple guest names, if any of them
    // were taken by the home name
    if (possibleGuestNames.length > 1) {
      possibleGuestNames = possibleGuestNames.filter((name) => {
        return name["name"] !== possibleHomeNames[0]["name"]
      })
    }

    const guests: Guest[] = rows
      .filter((row) => row !== "")
      .map((row) => {
        const data = splitComponentsByComma(row)
        let name = data[possibleGuestNames[0]["index"]]
        if (name[0] === '"' || name[0] === "'") {
          name = name.substr(1)
        }
        if (name[name.length - 1] === '"' || name[name.length - 1] === "'") {
          name = name.substr(0, name.length - 1)
        }

        const checkIn = parseISO(data[possibleCheckIn[0]["index"]])
        const checkOut = parseISO(data[possibleCheckOut[0]["index"]])
        const homeName = data[possibleHomeNames[0]["index"]]
        let home = this.homes?.find((home) => {
          return (
            home.name?.includes(homeName) ||
            homeName.includes(home.name || DEFAULT_HOME_NAME(home.home_id))
          )
        })
        if (home === undefined) {
          home = { name: homeName, home_id: homeName }
        }
        let phone: string = ""

        if (possiblePhones.length > 1) {
          for (let i = 0; i < possiblePhones.length; ++i) {
            // TODO WEB-XXX: do some phone parsing here to determine if if a valid number
            const value = data[possiblePhones[i].index]
            if (value !== "") {
              phone = value
            }
          }
        } else {
          phone = data[possiblePhones[0].index]
        }

        return {
          name,
          checkIn,
          checkOut,
          home,
          phone,
          errors: [],
        }
      })

    const validGuests = []
    const invalidGuests = []
    for (let i = 0; i < guests.length; ++i) {
      const guest = guests[i]
      if (guest.home?.home_id === undefined) {
        guest.errors?.push(`Could not find home for "${guest.home?.name}"`)
      }
      if (isNaN(guest.checkIn.getTime())) {
        guest.errors?.push("Not a valid check-in time")
      }
      if (isNaN(guest.checkOut.getTime())) {
        guest.errors?.push("Not a valid check-out time")
      }
      if (guest.errors?.length === 0) {
        validGuests.push(guest)
      } else {
        invalidGuests.push(guest)
      }
    }

    return {
      validGuests: validGuests,
      invalidGuests: invalidGuests,
      errors: [],
    }
  }

  process(): IParsedResult | undefined {
    const type = this.detectType()
    console.log(`This file is of type: ${type}`)
    if (type === FileType.CSV) {
      return this.parseCsv()
    }
  }
}

function splitComponentsByComma(str: string): string[] {
  const data = Papa.parse(str).data
  return data[0] as string[]
}
