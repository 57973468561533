import styled from "styled-components"

import {
  TIntegrationEntities,
  TIntegrationHomeLink,
} from "src/data/integrations/types/integrationTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MBanner, TAlert } from "src/ui/MBanner/MBanner"

export function LinkStatus({
  devices,
  isGoogleNestIntegration,
  isBrokenIntegration,
  linkStatus,
}: {
  devices: TIntegrationEntities[number]["devices"]
  isGoogleNestIntegration: boolean
  isBrokenIntegration: boolean
  linkStatus?: TIntegrationHomeLink["link_status"]
}) {
  const { t, langKeys } = useTranslate()

  const { text, type } = deviceStatus({ devices, t })

  if (isBrokenIntegration) {
    return (
      <StatusBox>
        <MBanner type="neutral" size="small">
          {t(langKeys.unknown)}
        </MBanner>
      </StatusBox>
    )
  }

  if (linkStatus === "linked") {
    if (isGoogleNestIntegration) {
      return (
        <StatusBox>
          <MBanner type={type} size="small">
            {t(langKeys.placeholder_web, { text })}
          </MBanner>
        </StatusBox>
      )
    }

    return (
      <StatusBox>
        <MBanner type="good" size="small">
          {t(langKeys.active)}
        </MBanner>
      </StatusBox>
    )
  }

  if (linkStatus === "broken") {
    return (
      <StatusBox>
        <MBanner type="error" size="small">
          {t(langKeys.integrations_broken_link_alert_text)}
        </MBanner>
      </StatusBox>
    )
  }

  return <div></div>
}

function deviceStatus({
  devices,
  t,
}: {
  devices: TIntegrationEntities[number]["devices"]
  t: TTranslateFunction
}): {
  text: string
  type: TAlert
} {
  const deviceCount = devices?.length
  const offlineDevices = devices?.filter((d) => d.status === "offline")
  const offlineDevicesCount = offlineDevices?.length || 0

  if (deviceCount === 0) {
    return { text: t(langKeys.integration_no_devices), type: "warning" }
  }

  if (offlineDevicesCount > 0) {
    return {
      text: t(langKeys.integration_number_of_inactive_devices, {
        count: deviceCount,
      }),
      type: "error",
    }
  }

  return { text: t(langKeys.active), type: "good" }
}

const StatusBox = styled.div`
  display: flex;
  align-items: flex-start;
`
