import { Dispatch, SetStateAction, useState } from "react"

import { usePostIntegrationConsentConfirmedEvent } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { useConnectOauth } from "src/data/integrations/queries/integrationAuthQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"

import { BeforeYouContinueDialog } from "./BeforeYouContinueDialog"
import { ClientCredentialDialog } from "./ClientCredentialDialog"
import { MultiSecretDialog } from "./MultiSecretDialog"
import { SecretDialog } from "./SecretDialog"

export function IntegrationConnectDialogs({
  integration,
  orgId,
  setStartAuthFlow,
  startAuthFlow,
  reauthorizationRequired = false,
}: {
  integration: TIntegration
  orgId: string
  setStartAuthFlow: Dispatch<SetStateAction<boolean>>
  startAuthFlow: boolean
  reauthorizationRequired?: boolean
}) {
  const connectOauth = useConnectOauth({ ownerType: OwnerType.ORGANIZATION })
  const [redirecting, setRedirecting] = useState(false)
  const [showSecretDialog, setShowSecretDialog] = useState(false)
  const [showMultiSecretDialog, setShowMultiSecretModal] = useState(false)
  const [showClientCredModal, setShowClientCredDialog] = useState(false)

  const postIntegrationConsentConfirmedEvent =
    usePostIntegrationConsentConfirmedEvent()

  function handleOauthFlow() {
    setRedirecting(true)
    connectOauth.mutate(
      {
        orgId,
        redirectUrl: window.location.href, // TODO WEB-xxx: Use react-router instead?
        integration: integration.integration_identifier,
      },
      {
        onError: (e) => {
          setRedirecting(false)
        },
      }
    )
  }

  function handleShowMultiSecretDialog() {
    setShowMultiSecretModal(true)
    setStartAuthFlow(false)
  }

  function handleShowSecretDialog() {
    setShowSecretDialog(true)
    setStartAuthFlow(false)
  }

  function handleClientCredentials() {
    setShowClientCredDialog(true)
    setStartAuthFlow(false)
  }

  function handleAgreedPrivacy() {
    postIntegrationConsentConfirmedEvent.mutate({
      integration_name: integration.name,
    })

    switch (integration.auth.type) {
      case "SECRET":
        return handleShowSecretDialog()
      case "MULTI_SECRET":
        return handleShowMultiSecretDialog()
      case "OAUTH2":
        return handleOauthFlow()
      case "OAUTH2_CLIENT_CREDENTIALS":
        return handleClientCredentials()
    }
  }

  return (
    <>
      <BeforeYouContinueDialog
        name={integration.name}
        integrationId={integration.integration_identifier}
        open={startAuthFlow}
        onClose={() => setStartAuthFlow(false)}
        onConfirm={handleAgreedPrivacy}
        loading={redirecting}
        type={integration.type}
      />
      <ClientCredentialDialog
        integration={integration}
        open={showClientCredModal}
        onClose={() => setShowClientCredDialog(false)}
        orgId={orgId}
        ownerType={OwnerType.ORGANIZATION}
        reauthorize={reauthorizationRequired}
      />
      <SecretDialog
        integration={integration}
        open={showSecretDialog}
        onClose={() => setShowSecretDialog(false)}
        orgId={orgId}
        ownerType={OwnerType.ORGANIZATION}
        reauthorize={reauthorizationRequired}
      />
      <MultiSecretDialog
        integration={integration}
        open={showMultiSecretDialog}
        onClose={() => setShowMultiSecretModal(false)}
        orgId={orgId}
        ownerType={OwnerType.ORGANIZATION}
        reauthorize={reauthorizationRequired}
      />
    </>
  )
}
