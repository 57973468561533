/* eslint-disable no-console */
import { useState } from "react"
import styled from "styled-components"

import { CsvImportErrors } from "src/components/Integrations/StaticIntegrations/FileImport/ErrorBox"
import { API_DEFAULT } from "src/constants/minutApi"
import { deleteGuest, fetchGuests } from "src/data/guests/queries/guestQueries"
import { HOME_MAX_LIMIT } from "src/data/homes/logic/homeConstants"
import { generateOffsets } from "src/data/homes/logic/homeUtil"
import { IFetchHomes } from "src/data/homes/types/homeQueryTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { fetchHomes } from "src/data/organizations/queries/homeQueries"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { SectionTitle } from "src/ui/Layout/SectionTitle"
import { MLink } from "src/ui/Link/MLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { ErrorService } from "src/utils/ErrorService"
import { chunk, sleep } from "src/utils/genericUtil"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

import { FilePasteContainer } from "./FilePastePopup"
import { FileParser, IParsedResult } from "./Parser"
import { TimePickPopup } from "./TimePickPopup"

/**
 * This component will use a CSV file and match it against a user's homes in an
 * attempt to import guest data for each applicable home.
 *
 * The current implementation relies on being able to access all homes
 * available to a user when correlating CSV data to homes, and it's used in
 * error handling and best guesses.
 */
export function UploadFile() {
  const { org } = useOrganization()
  const [importedState, setImportedState] = useState<IParsedResult>()
  const [askAboutTime, setAskAboutTime] = useState(false)
  const [checkinTime, setCheckinTime] = useState<Date>()
  const [checkoutTime, setCheckoutTime] = useState<Date>()
  const [processing, setProcessing] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  // TODO WEB-XXX: Combine sleepTime and limit with total nbr of homes from cache to
  // show an ETA of how long the import will take
  const sleepTimeMs = 1000
  const limit = HOME_MAX_LIMIT

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const addGuest = async (guest: any) => {
    const homeId = guest.home.home_id
    await minutApiHttpClient.post(`${API_DEFAULT}/homes/${homeId}/guests`, {
      name: guest.name,
      phone_number: guest.phone,
      checkin_time: guest.checkIn.toISOString(),
      checkout_time: guest.checkOut.toISOString(),
      source: "csv",
    })
  }

  async function purgeGuests(homeId: string) {
    const guestsResponse = await fetchGuests(homeId)
    const csvGuests = guestsResponse.guests.filter((g) => g.source === "csv")
    const chunkedGuests = chunk(csvGuests, 5)
    for (const guestChunk of chunkedGuests) {
      for (const guest of guestChunk) {
        console.log("purging", guest)
        deleteGuest(homeId, guest.guest_id)
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const handleSubmit = async ({ rawData, dryRun }: any) => {
    console.log(`handleSubmit`, { rawData, dryRun })
    if (!rawData) {
      return
    }
    try {
      setProcessing(true)

      setCheckinTime(new Date("2014-08-18T12:00:54"))
      setCheckoutTime(new Date("2014-08-18T12:00:54"))

      const homes = await getHomesAll({ orgId: org.id, limit, sleepTimeMs })
      console.log(homes)
      const parser = new FileParser(rawData, homes)
      const parsedResult = parser.process()
      console.log(parsedResult)
      let timeInputRequired = false
      parsedResult?.validGuests.forEach((guest) => {
        if (
          guest.checkIn.getHours() === 0 &&
          guest.checkIn.getMinutes() === 0 &&
          guest.checkOut.getHours() === 0 &&
          guest.checkOut.getMinutes() === 0
        ) {
          timeInputRequired = true
        }
      })

      setImportedState(parsedResult)

      if (timeInputRequired) {
        setAskAboutTime(true)
        setProcessing(false)
        return
      }

      if (!dryRun && parsedResult !== undefined) {
        const purgedHomes = new Set<string>([])
        const chunkedGuests = chunk(parsedResult.validGuests, 5)
        for (const guestChunk of chunkedGuests) {
          for (const guest of guestChunk) {
            const homeId = guest?.home?.home_id
            if (homeId && !purgedHomes.has(homeId)) {
              console.log("calling purge for home", homeId, purgedHomes)
              await purgeGuests(homeId)
              purgedHomes.add(homeId)
            }
            await addGuest(guest)
          }
          console.log("sleeping...")
          await sleep(1050)
        }
      }
    } catch (error) {
      ErrorService.captureException(error)
      setErrorMsg("Something went wrong.")
    } finally {
      setProcessing(false)
    }
  }

  const submitTimePick = () => {
    setAskAboutTime(false)
    const guests = importedState?.validGuests.map((guest) => {
      if (checkinTime) {
        guest.checkIn.setHours(checkinTime.getHours())
        guest.checkIn.setMinutes(checkinTime.getMinutes())
      }
      if (checkoutTime) {
        guest.checkOut.setHours(checkoutTime.getHours())
        guest.checkOut.setMinutes(checkoutTime.getMinutes())
      }
      return guest
    })
    guests?.forEach(addGuest)
  }

  const handleCheckInTime = (date: Date | undefined) => {
    console.log(`Set checkin time ${date}`)
    setCheckinTime(date)
    console.log(importedState)
  }

  const handleCheckOutTime = (date: Date | undefined) => {
    console.log(`Set checkout time ${date}`)
    setCheckoutTime(date)
  }

  return (
    <Section>
      <MBanner type="warning" fullWidth style={{ marginBottom: "1rem" }}>
        NOTE: This is a Beta feature; use at your own risk! Contact{" "}
        <MLink href="mailto:johan@minut.com">johan@minut.com</MLink> if you
        encounter any issues.
      </MBanner>

      <SectionTitle>Paste a CSV file</SectionTitle>

      <FilePasteContainer onSubmit={handleSubmit} loading={processing} />

      {!!errorMsg && (
        <MBanner type="warning" fullWidth>
          {errorMsg}
        </MBanner>
      )}

      {!processing && <CsvImportErrors importedState={importedState} />}

      <TimePickPopup
        // TODO WEB-XXX: Doesn't seem to be used at the moment
        open={askAboutTime}
        onClose={() => setAskAboutTime(false)}
        checkInSet={handleCheckInTime}
        checkOutSet={handleCheckOutTime}
        buttons={
          <Buttons>
            <CancelButton
              onClick={() => {
                console.log("Clicked cancel")
                setAskAboutTime(false)
              }}
            >
              <Label>Cancel</Label>
            </CancelButton>
            <MButtonLegacy onClick={submitTimePick}>
              <Label>Submit</Label>
            </MButtonLegacy>
          </Buttons>
        }
      />
    </Section>
  )
}

async function getHomesAll({
  orgId,
  limit = HOME_MAX_LIMIT,
  sleepTimeMs = 2000,
}: {
  orgId: string
  limit?: number
  sleepTimeMs?: number
}) {
  const homesQuery = await fetchHomes({
    orgId,
    filters: { limit, offset: 0 },
  })

  const firstPageQuery: IFetchHomes = homesQuery

  let homes: IHome[] = firstPageQuery.homes
  const totalHomes = firstPageQuery.paging?.total_count || 0
  const offsets = generateOffsets(limit, totalHomes, 1)

  for (const offset of offsets) {
    await sleep(sleepTimeMs)
    const r = await fetchHomes({
      orgId,
      filters: { limit, offset },
    })
    const extraHomes = r.homes || []
    homes = [...homes, ...extraHomes]
  }

  return homes
}

const Section = styled.div``

const Label = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CancelButton = styled(MButtonLegacy)`
  background-color: white;
  color: black;
  border: 1px solid grey;
  margin-right: 10px;
`
